import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../pli/pli-input';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';

@customElement('set-property')
class SetProperty extends LitElement {
    @property()
    value?: string;
    _emit = (event: PliInputChangeEvent) => {
        event.stopPropagation();
        const value = event.detail.value;

        this.dispatchEvent(
            new CustomEvent('change', {
                composed: true,
                detail: {
                    value,
                },
            }),
        );
    };
    render() {
        const { value, _emit } = this;
        return html` <pli-input size="sm" @change="${_emit}" value="${value}" placeholder="Set property"></pli-input>`;
    }
}
