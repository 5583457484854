import { RuleStatus } from 'domain/status/rule-status';
import { RuleDetails, RuleDetailsHistory } from '../types/rules-details-types';
import { getFormattedDateInputTime } from 'utils/datetime-formatter';
import { GetRuleResponse } from 'tms-client';

export const ruleResponseMapper = (res: GetRuleResponse): RuleDetails => {
    const mapRuleState = (state: GetRuleResponse['state']): RuleStatus => {
        switch (state) {
            case 'Preview':
                return 'Preview';
            case 'Live':
                return 'Live';
            case 'Editing':
                return 'Editing';
            case 'Paused':
                return 'Paused';
        }
    };

    const mapHistory = (history: GetRuleResponse['history']): RuleDetailsHistory[] =>
        Object.entries(history).map(([key, value]) => ({
            date: key,
            alerts: value.alerts,
            falsePositives: value.falsePositives,
        }));

    const mapStartDate = (startDate: GetRuleResponse['startDate']) =>
        startDate ? getFormattedDateInputTime(new Date(res.startDate)) : null;

    return {
        id: res.ruleId,
        alerts: res.alerts,
        name: res.name ?? '',
        description: res.description ?? '',
        processed: res.processed ?? 0,
        percentage: res.percentage ?? 0,
        startDate: mapStartDate(res.startDate),
        triggers: res.triggers ?? [],
        conditions: res.conditions ?? [],
        state: mapRuleState(res.state),
        history: mapHistory(res.history),
    };
};
