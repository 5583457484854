import { z } from 'zod';
import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { Action, RuleDetails } from './types/rules-details-types';
import { TriggersUpdatedEvent } from './components/triggers/rules-details-given';
import { ConditionsUpdatedEvent } from './components/conditions/rules-details-when';

import 'pli/pli-icon';
import 'pli/pli-button';
import 'pli/pli-card';
import 'pli/pli-input';
import 'pli/pli-textarea';
import 'pli/pli-progress-stepper';
import 'pli/pli-dialog';
import './rules-details-simulation';
import './components/triggers/rules-details-given';
import './components/conditions/rules-details-when';

import { Task } from '@lit/task';
import { consume, provide } from '@lit/context';
import { triggerContext } from './context/trigger-context';
import { conditionContext } from './context/condition-context';
import { styles } from 'pli/styles';
import { Router, routerContext } from 'context/router-context';
import { RuleStatus, ruleStatusKeys } from 'domain/status/rule-status';
import { ruleResponseMapper } from './mappers/rule-response-mapper';
import { AsyncIntervalController } from 'controllers/async-interval-controller';
import { RuleDetailPreviewUpdatedEvent } from './rules-details-simulation';
import { when } from 'lit/directives/when.js';
import { ToastController } from 'controllers/toast-controller';
import { toastServiceContext } from 'context/toast-service-context';

import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import {
    getRule,
    changeStartDateRule,
    ChangeStartDateRuleData,
    reviewRule,
    PauseRuleData,
    ResumeRuleData,
    GetRuleResponse,
    Trigger,
    Condition,
    resumeRule,
    pauseRule,
    deleteRule,
    deployRule,
    cloneRule,
    saveRule,
    updateRule,
    previewRule,
} from 'tms-client';
import debounce from 'just-debounce';

const nameSchema = z.string().min(1);
const descSchema = z.string().min(1);

const availableRequiredField = ['name', 'description'] as const;
type RequiredField = (typeof availableRequiredField)[number];

@customElement('rules-details')
class RulesDetails extends LitElement {
    static styles = [
        styles.base,
        styles.flex,
        styles.grid,
        styles.form,
        css`
            :host {
                --bottom-offset: var(--size-4-5);
                --button-group-height: var(--bottom-offset);
            }
            .wrapper {
                --padding-bottom: calc(var(--bottom-offset) + var(--size-1));
                padding-bottom: var(--padding-bottom);
            }
            .relative {
                position: relative;
            }
            .button-group {
                --offset: var(--sidebar-width);
                --width: calc(100% - var(--offset));
                position: fixed;
                bottom: 0;
                left: var(--offset);
                width: var(--width);
                height: var(--button-group-height);
                background-color: var(--color-white);
                z-index: var(--z-10);
                border-top: var(--border-default);
                padding: 0 var(--size-1);
            }
            fieldset {
                appearance: none;
                border: 0;
            }

            .condition {
                background-color: var(--color-white);
                padding-left: var(--size-1);
                border-radius: var(--radius-md);
            }

            .back-button {
                margin-left: calc(var(--size-1) * -1);
            }
        `,
    ];

    /* for passing of rule id from aurelia view into this component */
    @property({ type: String, reflect: true })
    ruleid?: string;

    @consume({ context: routerContext, subscribe: true })
    @property({ attribute: false })
    public router?: Router;

    @consume({ context: toastServiceContext, subscribe: true })
    @property({ attribute: false })
    public toastService: ToastController;

    @consume({ context: errorDialogContext })
    errorDialog?: ErrorDialogController;

    /* Provided state for child components */
    @provide({ context: triggerContext })
    private _triggers: Trigger[] = [];

    @provide({ context: conditionContext })
    private _conditions: Condition[] = [];

    /* Rule details page state */
    @state()
    _name = null;

    @state()
    _description = null;

    @state()
    _actions: Action[] = [];

    @state()
    _isLoading = false;

    @state()
    _state: RuleStatus = 'Editing';

    @state()
    _ruleDetails: RuleDetails;

    @state()
    _id: string | null = null;

    @state()
    _issues = [];

    _fieldValidationMap = {
        name: () => nameSchema.safeParse(this._name),
        description: () => descSchema.safeParse(this._description),
    };

    _asyncIntervalController = new AsyncIntervalController(this);

    _task = new Task(this, {
        task: async ([id]) => {
            if (!id) return;

            const { data, error } = await getRule({ path: { id: id } });

            if (error) {
                return this.errorDialog.showError();
            }

            if (data.percentage === 100) {
                this.stopPollingForUpdatedRule();
            }
            this.mapResponseToViewData(data);
        },
        args: () => [this._id] as const,
    });

    connectedCallback(): void {
        super.connectedCallback();
        this._id = this.ruleid ?? null;

        if (this.showPreview) {
            this.startPollingForUpdatedRule();
        }
    }

    /* Methods for handling emitted events from child components */
    handleTriggersUpdate = (event: TriggersUpdatedEvent) => {
        this._triggers = [...event.detail.value];
    };

    handleConditionsUpdate = (event: ConditionsUpdatedEvent) => {
        this._conditions = [...event.detail.value];
    };

    isoString(date: Date): string {
        return
    }

    handlePreviewUpdate = async (event: RuleDetailPreviewUpdatedEvent) => {
        const from = event.detail.value?.from;
        const { data, error } = await changeStartDateRule({
            path: { id: this._id },
            body: { from: from?.toISOString() },
        });

        if (error) {
            this.errorDialog.showError();
            return;
        }

        this.startPollingForUpdatedRule();
    };

    /* Methods for handling internal state */
    displaySuccessToast = debounce(() => this.toastService.success({ bodyText: 'Rule created', lifespan: 5000 }), 1000);

    validateFieldByName = (field: RequiredField) => {
        const parsed = this._fieldValidationMap[field]();

        if (parsed.error) {
            this.setIssues([...this._issues, field]);
        } else {
            this.setIssues([...this._issues.filter((issue) => issue !== field)]);
        }
    };

    validateRequiredFields = (): { isValid: boolean } => {
        for (const [fieldName, parseFunc] of Object.entries(this._fieldValidationMap)) {
            const result = parseFunc();
            if (result.error) {
                this.setIssues([...this._issues, fieldName]);
            }
        }
        const isValid = this.issues.length === 0;
        return { isValid };
    };

    saveInternal = async () => {
        const { isValid } = this.validateRequiredFields();
        if (!isValid) return;

        try {
            if (!this._id) {
                const { data, error } = await saveRule({
                    body: {
                        name: this._name,
                        description: this._description,
                        triggers: this._triggers,
                        conditions: this._conditions,
                        actions: [],
                    },
                });

                if (error) {
                    throw new Error(`Could not save rule, reason: ${(<any>error).message}`);
                }

                this._id = data.ruleId;

                this.router.navigate(`/rules/${data.ruleId}`, {
                    replace: true,
                });
                this.displaySuccessToast();
            } else {
                const { data, error } = await updateRule({
                    path: { id: this._id },
                    body: {
                        name: this._name,
                        description: this._description,
                        triggers: this._triggers,
                        conditions: this._conditions,
                        actions: [],
                    },
                });

                if (error) {
                    throw new Error(`Could not save rule, reason: ${(<any>error).message}`);
                }

                this._task.run();
                this.displaySuccessToast();
            }
        } catch (error) {
            const errorMessages = error.errors ? Object.values(error.errors).map((errorArray) => errorArray[0]) : [];
            this.errorDialog.showError({
                title: 'Save error',
                errorMessage: error.message + ' ' + errorMessages.join(', '),
            });
        }
    };

    setIssues(issues: string[]) {
        this._issues = issues;
    }

    setRuleStatus(state: RuleStatus) {
        this._state = state;
    }

    async startPollingForUpdatedRule() {
        this._asyncIntervalController.setAsyncInterval(async () => {
            await this._task.run();
        }, 2_000);
    }

    stopPollingForUpdatedRule() {
        const { clearAsyncInterval, currentIntervalIndex } = this._asyncIntervalController;
        clearAsyncInterval(currentIntervalIndex);
    }

    preview = async () => {
        try {
            this._isLoading = true;
            await this.saveInternal();

            const { data, error } = await previewRule({ path: { id: this._id } });

            if (error) {
                return this.errorDialog.showError();
            }

            this.setRuleStatus('Preview');
            this.startPollingForUpdatedRule();
        } finally {
            this._isLoading = false;
        }
    };

    review = async () => {
        try {
            this._isLoading = true;
            const { data, error } = await reviewRule({ path: { id: this._id } });

            if (error) {
                return this.errorDialog.showError();
            }

            this.setRuleStatus('Editing');
            this.stopPollingForUpdatedRule();
        } finally {
            this._isLoading = false;
        }
    };

    pause = async () => {
        try {
            this._isLoading = true;
            const { data, error } = await pauseRule(<PauseRuleData>{ path: { id: this._id } });

            if (error) {
                return this.errorDialog.showError();
            }

            this.setRuleStatus('Paused');
        } finally {
            this._isLoading = false;
        }
    };

    resume = async () => {
        try {
            this._isLoading = true;
            const { data, error } = await resumeRule(<ResumeRuleData>{ path: { id: this._id } });

            if (error) {
                return this.errorDialog.showError();
            }

            this.setRuleStatus('Live');
        } finally {
            this._isLoading = false;
        }
    };

    private mapResponseToViewData(json: GetRuleResponse) {
        const mappedData = ruleResponseMapper(json);
        const { name, description, triggers, conditions, state, history } = mappedData;
        this._ruleDetails = { ...mappedData };
        this._name = name;
        this._description = description;
        this._triggers = triggers;
        this._conditions = conditions;
        this.setRuleStatus(state);
    }

    get ruleDetails(): RuleDetails {
        return {
            ...this._ruleDetails,
            state: this._state,
        };
    }
    get hasRequiredFields() {
        return [this._name, this._description].every((e) => Boolean(e));
    }
    get isFormEnabled() {
        return this._state === 'Editing' && !this._isLoading;
    }
    get isSimulationEnabled() {
        return this.showPreview && Boolean(this.ruleDetails);
    }
    get showPreview() {
        return this._state !== 'Editing';
    }
    get issues() {
        return this._issues;
    }

    _renderDeleteRuleDialog = () => {
        const handleDeleteRule = async () => {
            const { error } = await deleteRule({
                path: { id: this.ruleid },
                parseAs: 'stream',
            });

            if (error) {
                return this.errorDialog?.showError({ title: 'Could not delete rule' });
            }
            this.router.navigate('rules');
        };

        return html`
            <pli-dialog>
                <pli-dialog-open-button
                    class="flex justify-end items-center"
                    slot="open-button"
                    variant="destructive"
                    width="hug-content"
                    ><pli-icon name="trash" slot="icon-left"></pli-icon>Delete</pli-dialog-open-button
                >

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="question-circle" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Delete Rule?</pli-text>
                    <p>Are you sure you want to delete the rule? This action cannot be undone.</p>
                </pli-dialog-content>

                <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                <pli-dialog-primary-button
                    @pli-dialog-primary-dismiss="${() => handleDeleteRule()}"
                    variant="destructive"
                    slot="primary-button"
                    >Delete</pli-dialog-primary-button
                >
            </pli-dialog>
        `;
    };

    _renderDeployRuleDialog = () => {
        const handleDeployRule = async () => {
            this._isLoading = true;
            const { error } = await deployRule({
                path: { id: this.ruleid },
                parseAs: 'stream',
            });

            if (error) {
                this._isLoading = false;
                return this.errorDialog?.showError({ title: 'Could not deploy rule' });
            }
            this.setRuleStatus('Live');
            this.startPollingForUpdatedRule();
            this._isLoading = false;
        };

        return html`
            <pli-dialog>
                <pli-dialog-open-button class="flex justify-end items-center" slot="open-button" width="hug-content"
                    ><pli-icon name="send-check" slot="icon-left"></pli-icon>Deploy</pli-dialog-open-button
                >

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="question-circle" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Deploy Rule?</pli-text>
                    <p>Are you sure you want to deploy the rule. This will create alerts.</p>
                </pli-dialog-content>

                <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                <pli-dialog-primary-button
                    @pli-dialog-primary-dismiss="${() => handleDeployRule()}"
                    variant="destructive"
                    slot="primary-button"
                    >Deploy</pli-dialog-primary-button
                >
            </pli-dialog>
        `;
    };

    _renderDuplicateRuleDialog = () => {
        const handleDuplicateRule = async () => {
            const { data, error } = await cloneRule({
                path: { id: this.ruleid },
            });

            if (error) {
                return this.errorDialog?.showError({ title: 'Could not duplicate rule' });
            }
            this.router.navigate(`/rules/${data.ruleId}`);
        };

        return html`
            <pli-dialog>
                <pli-dialog-open-button class="flex justify-end items-center" slot="open-button" width="hug-content"
                    ><pli-icon name="clouds" slot="icon-left"></pli-icon>Duplicate</pli-dialog-open-button
                >

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="question-circle" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Duplicate rule?</pli-text>
                    <p>Are you sure you want to duplicate the rule: ${this._name}</p>
                </pli-dialog-content>

                <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                <pli-dialog-primary-button
                    @pli-dialog-primary-dismiss="${() => handleDuplicateRule()}"
                    variant="destructive"
                    slot="primary-button"
                    >Duplicate</pli-dialog-primary-button
                >
            </pli-dialog>
        `;
    };

    _renderEditingButtonGroup = () => {
        return html`
            <pli-button size="lg" .onClick="${this.saveInternal}">
                <pli-icon name="save" slot="icon-left"></pli-icon>
                Save
            </pli-button>
            <pli-button size="lg" ?disabled="${!this.hasRequiredFields}" .onClick="${this.preview}">
                <pli-icon name="send" slot="icon-left"></pli-icon>
                Preview
            </pli-button>
            ${this._renderDeleteRuleDialog()}
        `;
    };
    _renderPreviewButtonGroup = () => {
        return html`
            <pli-button size="lg" .onClick="${this.review}">
                <pli-icon name="arrow-counterclockwise" slot="icon-left"></pli-icon>
                Edit
            </pli-button>
            ${this._renderDeployRuleDialog()} ${this._renderDeleteRuleDialog()}
        `;
    };
    _renderLiveButtonGroup = () => {
        return html` <pli-button size="lg" variant="secondary" .onClick="${this.pause}">
                <pli-icon name="pause-fill" slot="icon-left"></pli-icon>
                Pause
            </pli-button>
            ${this._renderDuplicateRuleDialog()}`;
    };
    _renderPausedButtonGroup = () => {
        return html` <pli-button size="lg" variant="secondary" .onClick="${this.resume}">
                <pli-icon name="play-fill" slot="icon-left"></pli-icon>
                Resume
            </pli-button>
            ${this._renderDuplicateRuleDialog()}`;
    };
    _renderButtons = (state: RuleDetails['state']) => {
        const buttonGroups: Record<RuleDetails['state'], TemplateResult> = {
            Live: this._renderLiveButtonGroup(),
            Editing: this._renderEditingButtonGroup(),
            Preview: this._renderPreviewButtonGroup(),
            Paused: this._renderPausedButtonGroup(),
        };
        return buttonGroups[state];
    };
    _renderProgressStepper = (state: RuleDetails['state']) => {
        const filtered: RuleDetails['state'][] = [...ruleStatusKeys.filter((status) => status !== 'Paused')];
        const steps = filtered.length;

        // Paused AND Live should both be treated as a fulfilled last step in stepper
        const lastStepsMerged: RuleStatus[] = ['Paused', 'Live'];
        const currentStep = lastStepsMerged.includes(state) ? filtered.length : filtered.indexOf(state);

        return html`<pli-progress-stepper steps="${steps}" currentStep="${currentStep}"></pli-progress-stepper>`;
    };

    render() {
        const {
            handleTriggersUpdate,
            handleConditionsUpdate,
            handlePreviewUpdate,
            _state,
            ruleDetails,
            _renderButtons,
            _renderProgressStepper,
            isFormEnabled,
            showPreview,
        } = this;

        return html`
            <div class="grid-vertical gap-1 relative wrapper">
                <pli-button class="back-button" variant="text" as="a" size="lg" href="/rules">
                    <pli-icon name="arrow-left-circle" slot="icon-left"></pli-icon>
                    Back to rules
                </pli-button>
                <pli-text as="h1" variant="h1">Rule</pli-text>
                <pli-card>
                    <div class="grid gap-2">
                        <form class="grid-vertical gap-2 col-span-7" .inert="${!isFormEnabled}">
                            <fieldset class="grid-vertical gap-1">
                                <label><strong>Name*</strong></label>
                                <pli-input
                                    value="${this._name}"
                                    .state="${this.issues.includes('name') ? 'invalid' : 'initial'}"
                                    @change="${(event) => {
                                        this._name = event.detail.value;
                                        this.validateFieldByName('name');
                                    }}"
                                    placeholder="Name of the rule"
                                ></pli-input>
                                ${this.issues.includes('name')
                                    ? html`<pli-alert-box variant="error">Name is required</pli-alert-box>`
                                    : null}
                            </fieldset>
                            <fieldset class="grid-vertical gap-1">
                                <label><strong>Description *</strong></label>
                                <pli-textarea
                                    .value="${this._description}"
                                    .state="${this.issues.includes('description') ? 'invalid' : 'initial'}"
                                    @change="${(event) => {
                                        this._description = event.detail.value;
                                        this.validateFieldByName('description');
                                    }}"
                                    placeholder="A description for the rule"
                                ></pli-textarea>
                                ${this.issues.includes('description')
                                    ? html`<pli-alert-box variant="error">Description is required</pli-alert-box>`
                                    : null}
                            </fieldset>
                            <rules-details-given @update="${handleTriggersUpdate}"></rules-details-given>
                            <rules-details-when @update="${handleConditionsUpdate}"></rules-details-when>
                        </form>

                        <div class="col-span-5">
                            ${when(
                                this.isSimulationEnabled,
                                () => html`
                                    <rules-details-simulation
                                        @update="${handlePreviewUpdate}"
                                        .rule="${ruleDetails}"
                                    ></rules-details-simulation>
                                `,
                                () => html`
                                    <rules-details-simulation-placeholder></rules-details-simulation-placeholder>
                                `,
                            )}
                        </div>
                        <div class="grid button-group">
                            <div class="col-span-4 col-start-5">${_renderProgressStepper(_state)}</div>
                            <div class="col-span-4 flex items-center justify-end gap-05">${_renderButtons(_state)}</div>
                        </div>
                    </div>
                </pli-card>
            </div>
        `;
    }
}
