import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../pli/pli-text';
import { styles } from 'pli/styles';
import { RuleDetails } from './types/rules-details-types';
import '../../pli/pli-progress-bar';
import '../../pli/pli-input';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import '../../pli/pli-alert-box';
import { HttpClient, clientContext } from 'context/client-context';
import { consume } from '@lit/context';
import { ifDefined } from 'lit/directives/if-defined.js';
import '../../pli/pli-bar-chart';
import { when } from 'lit/directives/when.js';

@customElement('rules-details-simulation')
class RulesDetailsSimulation extends LitElement {
    static styles = [
        styles.grid,
        styles.flex,
        css`
            .canvas-wrapper {
                position: relative;
                aspect-ratio: 16 / 9;
            }

            canvas {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .divider {
                display: flex;
                align-items: center;
                gap: var(--size-0-5);
                color: var(--color-border);
            }

            .divider::before,
            .divider::after {
                flex: 1;
                content: '';
                display: block;
                height: 1px;
                background-color: currentColor;
            }

            .box {
                padding: var(--size-1);
                border-radius: var(--radius-md);
                background-color: var(--body-bg);
            }

            .alert {
                --bs-alert-color: var(--bs-info-text-emphasis);
                --bs-alert-bg: var(--bs-info-bg-subtle);
                --bs-alert-border-color: var(--bs-info-border-subtle);
                --bs-alert-link-color: var(--bs-info-text-emphasis);
                padding: var(--size-1);
                background-color: var(--bs-alert-bg);
                color: var(--bs-alert-color);
                border: 1px solid var(--bs-alert-border-color);
                border-radius: var(--radius-sm);
            }
        `,
    ];

    @property({ attribute: false })
    rule: RuleDetails;

    @consume({ context: clientContext })
    client: HttpClient;

    @state()
    _from: Date | null = null;

    @state()
    _future = false;

    _title = 'Simulation window';
    _description = 'Total number of alerts triggered based off previous transactions.';

    connectedCallback(): void {
        super.connectedCallback();
        if (this.rule.startDate) {
            this._setFromDate(this.rule.startDate);
        }
    }

    onDateChange = async (event: PliInputChangeEvent) => {
        this._setFromDate(event.detail.value);
        const value = event.detail.value;
        const date = new Date(value);
        this._future = date > new Date();

        const msg = {
            from: date,
        };

        const emittedEvent: RuleDetailPreviewUpdatedEvent = new CustomEvent('update', {
            composed: true,
            detail: {
                value: msg,
            },
        });
        this.dispatchEvent(emittedEvent);
    };

    get historyLabels() {
        return this.rule.history.map((h) => h.date);
    }
    get alerts() {
        return this.rule.history.map((h) => h.alerts);
    }
    get isLive() {
        return this.rule.state === 'Live' || this.rule.state === 'Paused';
    }
    get hasChosenFutureDate() {
        return this._from > new Date();
    }
    get hasChosenCurrentDate() {
        const f = (fromDate: Date, currentDate: Date) => {
            return (
                fromDate.getFullYear() === currentDate.getFullYear() &&
                fromDate.getMonth() === currentDate.getMonth() &&
                fromDate.getDate() === currentDate.getDate()
            );
        };

        return this._from ? f(this._from, new Date()) : false;
    }

    _setFromDate(date: string) {
        this._from = new Date(date);
    }

    /* Render helper methods */
    _renderFutureAlert = () =>
        html`<pli-alert-box variant="info">
            You have selected a date in the future. This means nothing can be previewed.
        </pli-alert-box>`;

    _renderDefaultWarning = () =>
        html`<pli-alert-box variant="info">
            Select a date you wish to start the rule from. This includes the date it starts to count from, if you have a
            cumulative rule.
        </pli-alert-box>`;

    _renderCurrentDateWarning = () =>
        html`<pli-alert-box variant="warning">
            The alerts displayed on the Preview Window will be generated if this data is set. The alerts are
            irreversible.
        </pli-alert-box>`;

    renderAlerts = () => {
        if (this.hasChosenFutureDate) {
            return html`${this._renderFutureAlert()}`;
        }
        if (this.hasChosenCurrentDate) {
            return html`${this._renderCurrentDateWarning()}`;
        }
        if (!Boolean(this._from)) {
            return html`${this._renderDefaultWarning()}`;
        }
    };

    render() {
        const { _title, _description, isLive, rule, onDateChange, renderAlerts, alerts, historyLabels } = this;
        const formattedStartDateValue = rule.startDate;

        return html`<div class="grid-vertical gap-1">
            <div class="grid-vertical gap-0-5">
                <pli-text as="h2" variant="h2">${_title}</pli-text>
                <pli-text as="p">${_description}</pli-text>
            </div>
            <pli-bar-chart .data="${alerts}" .labels="${historyLabels}" graphLabel="Alerts"></pli-bar-chart>
            <div class="divider">
                <pli-text>Progress</pli-text>
            </div>
            <pli-progress-bar size="sm" showPercentage radius="md" value="${rule.percentage}"></pli-progress-bar>
            <div class="grid">
                <pli-text class="col-span-6"><strong>Alerts Triggered</strong></pli-text>
                <pli-text class="col-span-6">${rule.alerts}</pli-text>
                <pli-text class="col-span-6"><strong>Transactions Screened</strong></pli-text>
                <pli-text class="col-span-6">${rule.processed}</pli-text>
            </div>
            ${when(
                !isLive,
                () => html`
                    <div class="box gray grid-vertical gap-1">
                        <pli-text><strong>Select a date you wish to start the rule from:</strong></pli-text>
                        <div class="input-group">
                            <pli-input
                                type="date"
                                value="${ifDefined(formattedStartDateValue)}"
                                @change="${onDateChange}"
                            ></pli-input>
                        </div>
                        ${renderAlerts()}
                    </div>
                `,
            )}
        </div>`;
    }
}

@customElement('rules-details-simulation-placeholder')
class RulesDetailsSimulationPlaceholder extends LitElement {
    static styles = [
        styles.base,
        styles.grid,
        styles.flex,
        css`
            :host {
                display: block;
                height: 100%;
                background-color: var(--body-bg);
                border-radius: var(--radius-md);
            }

            .preview-plate {
                height: inherit;
            }
        `,
    ];
    render() {
        return html`
            <div class="preview-plate flex items-center justify-center">
                <pli-text>Preview of rule</pli-text>
            </div>
        `;
    }
}

export type RuleDetailPreviewUpdatedEvent = CustomEvent<{ value: { from: Date } }>;
