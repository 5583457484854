import { css } from 'lit';

export const addToRuleSelectStyles = css`
    .select {
        position: relative;
    }

    .select pli-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: var(--size-1);
        z-index: 1;
        pointer-events: none;
    }

    .select select {
        appearance: none;
        padding: var(--size-1-5) var(--size-1);
        border-radius: var(--radius-md);
        border: 0;
        width: 100%;
        display: block;
        padding-left: var(--size-3);
        background-color: var(--color-white);
    }
`;
