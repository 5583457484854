import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import 'pli/pli-icon';
import 'pli/pli-select';

@customElement('select-operator')
class SelectOperator extends LitElement {
    @property()
    variant: 'extended' | 'default' = 'default';
    @property()
    value?: string;

    _options = [
        { value: 'eq', label: 'Equal To' },
        { value: 'neq', label: 'Not Equal To' },
        { value: 'lt', label: 'Less Than' },
        { value: 'le', label: 'Less or Equal To' },
        { value: 'ge', label: 'Greater or Equal To' },
        { value: 'gt', label: 'Greater Than' },
    ];

    _optionsExtended = [
        { value: 'contains', label: 'Contains' },
        { value: 'startswith', label: 'Starts With' },
        { value: 'endswith', label: 'Ends With' },
    ];

    render() {
        const { _options, _optionsExtended, value, variant } = this;
        const isExtended = variant === 'extended';
        const options = isExtended ? [..._options, ..._optionsExtended] : _options;

        return html`<pli-select value="${value}" .options="${options}" placeholder="Select operator"></pli-select>`;
    }
}
