import { LitElement, TemplateResult, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { gridStyles } from 'pli';

import '../../pli/pli-card';
import '../../pli/pli-button';
import '../../pli/pli-text';
import '../../pli/pli-table';
import '../../pli/pli-pagination';
import '../../pli/pli-status-label';
import '../../pli/pli-progress-bar';

import { defineHeaderItems } from '../../pli/pli-table';
import { Task } from '@lit/task';
import { consume } from '@lit/context';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { percentageFormatter } from 'utils/percentage-formatter';
import { PliStatusLabelVariant } from 'pli/pli-status-label';
import '../../pli/pli-pager';
import { getRuleList, GetRuleList_ItemResult } from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';

@customElement('rules-overview')
class RulesOverview extends LitElement {
    @consume({ context: errorDialogContext, subscribe: true })
    errorDialog: ErrorDialogController;

    static styles = [gridStyles];

    headerItems = defineHeaderItems({
        Name: {
            sortField: null,
            columnSpan: 4,
        },
        Created: {
            sortField: null,
            columnSpan: 2,
        },
        Alerts: {
            sortField: null,
            columnSpan: 1,
        },
        'False positives': {
            sortField: null,
            columnSpan: 2,
        },
        State: {
            sortField: null,
            columnSpan: 1,
        },
        Progress: {
            sortField: null,
            columnSpan: 1,
        },
    });

    @state()
    _total = 0;
    @state()
    _page = 1;

    _items: GetRuleList_ItemResult[] = [];

    _task = new Task(this, {
        task: async ([page]) => {
            const { data, error } = await getRuleList({ query: { page, sortField: 'Created' } });
            if (error) {
                return this.errorDialog.showError();
            }
            this._total = data.total;
            this._items = [...data.list];
        },
        args: () => [this._page] as const,
    });

    _renderItem(item: GetRuleList_ItemResult): TemplateResult {
        return html`<tr>
            <td>
                <a data-link="navigate" href="/rules/${item.ruleId}"> <strong>${item.name}</strong></a>
            </td>
            <td>${dateTimeFormatter(item.created.toString())}</td>
            <td>${item.alerts}</td>
            <td>${percentageFormatter(item.falsePositive, item.alerts)}</td>
            <td><pli-status-label variant="${item.state as PliStatusLabelVariant}"></pli-status-label></td>
            <td>
                ${percentageFormatter(item.percentage)}<pli-progress-bar value="${item.percentage}"></pli-progress-bar>
            </td>
        </tr>`;
    }

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    render() {
        return html`<div class="grid gap-1">
            <div class="col-span-9 col-lg-span-10">
                <pli-card>
                    <pli-text as="h1" variant="h1">Rules</pli-text>
                    <pli-pager
                        .items="${this._items}"
                        page="${this._page}"
                        total="${this._total}"
                        @page-update="${this._paginationHandler}"
                    >
                        <pli-table
                            .headerItems="${this.headerItems}"
                            .items="${this._items}"
                            .renderTemplate="${this._renderItem}"
                            sortOrderKey="rules-list-order"
                        >
                        </pli-table
                    ></pli-pager>
                </pli-card>
            </div>
            <div class="col-span-3 col-lg-span-2 align-self-start">
                <pli-card>
                    <pli-button as="a" href="/rules/new" size="lg" width="full">Create rule</pli-button>
                </pli-card>
            </div>
        </div>`;
    }
}
