import { Router, RouterConfiguration, activationStrategy } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { OpenIdConnectRoles } from 'aurelia-open-id-connect';
import { autoinject } from 'aurelia-framework';

@autoinject
export class Rules {
    public router: Router;

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.map([
            {
                route: '',
                name: 'rule-list',
                moduleId: PLATFORM.moduleName('./list/list'),
                nav: true,
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.replace,
            },
            {
                route: 'new',
                name: 'new-rule',
                moduleId: PLATFORM.moduleName('./details/details'),
                nav: false,
                title: 'Detail',
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.replace,
            },
            {
                route: ':id',
                name: 'rules-details',
                moduleId: PLATFORM.moduleName('./details/details'),
                nav: false,
                title: 'Detail',
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.replace,
            },
        ]);

        this.router = router;
    }
}
