import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from 'pli/styles';

@customElement('select-currency')
class SelectCurrency extends LitElement {
    static styles = [
        styles.base,
        styles.flex,
        css`
            input {
                appearance: none;
                padding: var(--size-0-5) var(--size-1);
                border-radius: var(--radius-md);
                background-color: var(--body-bg);
                border: var(--border-default);
                align-self: flex-start;
                width: 100%;
            }

            pli-input {
                display: inline-flex;
                width: auto;
            }
        `,
    ];

    @property()
    value?: string;

    emit(value: string) {
        this.dispatchEvent(
            new CustomEvent('currencyUpdate', {
                composed: true,
                detail: {
                    value,
                },
            }),
        );
    }

    handleValueChange = (event: Event) => {
        const value = (event.currentTarget as HTMLInputElement).value;
        this.emit(value);
    };

    _items = [
        { value: 'EURO', label: 'Euro', f: 'fi fi-eu' },
        { value: 'SEK', label: 'Sek', f: 'fi fi-se' },
        { value: 'USD', label: 'Usd', f: 'fi fi-us' },
    ];

    render() {
        return html`
            <input
                list="items"
                id="items-choice"
                name="items-choice"
                value="${this.value}"
                placeholder="Select currency"
                autocomplete="off"
                @input="${this.handleValueChange}"
            />
            ${Boolean(this._items.length)
                ? html`
                      <datalist id="items" role="listbox">
                          ${this._items.map((item) => html`<option value="${item.value}">${item.label}</option>`)}
                      </datalist>
                  `
                : null}
        `;
    }
}
