import { css } from 'lit';

export const ruleActionAddedStyles = css`
    .trigger {
        --transform-start: translateY(50%);
        --transform-end: translateY(0);
        --ease: cubic-bezier(0.06, 0.98, 0.19, 1);
        position: relative;
        transform: var(--transform-start);
        animation: slideUp 0.4s var(--ease) forwards 1;
    }
`;
