import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from 'pli/styles';
import 'pli/pli-input';
import 'pli/pli-select';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';

const availableOperators = ['day', 'week', 'month'] as const;
type TimeOperator = (typeof availableOperators)[number];

const labelMap: Record<TimeOperator, string> = {
    day: 'Days',
    week: 'Weeks',
    month: 'Months',
};

const avaliableOptions: { value: TimeOperator; label: string }[] = [
    { value: 'day', label: labelMap['day'] },
    { value: 'week', label: labelMap['week'] },
    { value: 'month', label: labelMap['month'] },
];

@customElement('select-time-period')
class SelectTimePeriod extends LitElement {
    @property()
    operator?: string;
    @property()
    value?: string;
    static styles = [styles.flex, styles.grid, styles.base, css``];

    emit({ value, operator }: { value: string; operator: string }) {
        this.dispatchEvent(
            new CustomEvent('timePeriodUpdate', {
                composed: true,
                detail: {
                    value,
                    operator,
                },
            }),
        );
    }

    handleValueChange = (event: PliInputChangeEvent) => {
        event.stopPropagation();
        this.emit({ operator: this.operator, value: event.detail.value });
    };

    handleOperatorChange = (event: CustomEvent<{ value: string }>) => {
        event.stopPropagation();
        this.emit({ operator: event.detail.value, value: this.value });
    };

    render() {
        return html`<div class="flex gap-1">
            <div>
                <pli-input
                    @change="${this.handleValueChange}"
                    value="${this.value}"
                    size="sm"
                    placeholder="Set value"
                ></pli-input>
            </div>
            <pli-select
                value="${this.operator}"
                placeholder="Select time unit"
                .options="${avaliableOptions}"
                @change="${this.handleOperatorChange}"
            ></pli-select>
        </div>`;
    }
}
