import { autoinject } from 'aurelia-framework';
import { cleanParamsObject } from 'utils/params';

@autoinject
export class RuleDetail {
    id: string;

    constructor() {}

    async activate(params) {
        params = cleanParamsObject(params);
        this.id = params.id;
    }

    async attached() {}
}
